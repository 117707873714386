<template>
  <div class="o-medicines-slider">
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"
    />
    <MRedirectPopup
      :is-visible="showRedirectPopup"
      redirect-text="Programa BemPerto Raras"
    />
    <div class="swiper-container">
      <div class="swiper-button-prev"></div>
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide o-medicines-slider__item ossos">
            <div class="o-medicines-slider__item__wrapper">
              <div class="image-wrapper">
                <img
                  src="../../assets/home-swiper/ossos.png"
                  alt="Pessoa - Ossos"
                />
              </div>
              <div class="content-wrapper">
                <div class="icon-wrapper">
                  <img
                    src="../../assets/home-swiper/icons/ossos.png"
                    alt="Ossos"
                  />
                </div>
                <h2>Ossos</h2>
              </div>
            </div>
          </div>

          <div class="swiper-slide o-medicines-slider__item intestino">
            <div class="o-medicines-slider__item__wrapper">
              <a
                :href="`${host}/intestino`"
                title="Programa BemPerto® do seu intestino"
              >
                <div class="image-wrapper">
                  <img
                    src="../../assets/home-swiper/intestino.png"
                    alt="Pessoa - Intestino"
                  />
                </div>
                <div class="content-wrapper">
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/home-swiper/icons/intestino.png"
                      alt="Intestino"
                    />
                  </div>
                  <h2>Intestino</h2>
                </div>
              </a>
            </div>
          </div>

          <div class="swiper-slide o-medicines-slider__item medula">
            <div class="o-medicines-slider__item__wrapper">
              <a
                :href="`${host}/medulaossea`"
                title="Programa BemPerto® da sua medula"
              >
                <div class="image-wrapper">
                  <img
                    src="../../assets/home-swiper/medula.png"
                    alt="Pessoa - Medula"
                  />
                </div>
                <div class="content-wrapper">
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/home-swiper/icons/medula.png"
                      alt="Medula"
                    />
                  </div>
                  <h2>Medula</h2>
                </div>
              </a>
            </div>
          </div>

          <div class="swiper-slide o-medicines-slider__item autoimunidade">
            <div class="o-medicines-slider__item__wrapper">
              <a
                :href="`${host}/autoimunidade`"
                title="Programa BemPerto® da sua autoimunidade"
              >
                <div class="image-wrapper">
                  <img
                    src="../../assets/home-swiper/autoimune.png"
                    alt="Pessoa - Autoimunidade"
                  />
                </div>
                <div class="content-wrapper">
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/home-swiper/icons/autoimune.png"
                      alt="Autoimunidade"
                    />
                  </div>
                  <h2>Autoimunidade</h2>
                </div>
              </a>
            </div>
          </div>

          <div class="swiper-slide o-medicines-slider__item pulmao">
            <a
              :href="`${host}/pulmao`"
              title="Programa BemPerto® do seu pulmão"
              class="o-medicines-slider__item__wrapper"
            >
              <div class="image-wrapper">
                <img
                  src="../../assets/home-swiper/pulmao.png"
                  alt="Pessoa - Pulmão"
                />
              </div>
              <div class="content-wrapper">
                <div class="icon-wrapper">
                  <img
                    src="../../assets/home-swiper/icons/pulmao.png"
                    alt="Pulmão"
                  />
                </div>
                <h2>Pulmão</h2>
              </div>
            </a>
          </div>

          <div class="swiper-slide o-medicines-slider__item raras">
            <div class="o-medicines-slider__item__wrapper">
              <a
                href="#"
                @click.prevent="handleRarasRedirect"
                title="Programa BemPerto® das doenças raras"
              >
                <div class="image-wrapper">
                  <img
                    src="../../assets/home-swiper/raras.png"
                    alt="Pessoa - Doenças Raras"
                  />
                </div>
                <div class="content-wrapper">
                  <div class="icon-wrapper">
                    <img
                      src="../../assets/home-swiper/icons/raras.png"
                      alt="Doenças Raras"
                    />
                  </div>
                  <h2>Raras</h2>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
  <div class="slider-instruction">
    <img src="../../assets/home-swiper/icons/arraste.png" alt="Instrução" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import MRedirectPopup from "@/components/molecules/RedirectPopup.vue";

// Declarar Swiper para o ESLint
/* global Swiper */

export default {
  name: "OMedicinesSlider",
  components: {
    MRedirectPopup
  },
  setup() {
    const showRedirectPopup = ref(false);

    const handleRarasRedirect = () => {
      showRedirectPopup.value = true;
      setTimeout(() => {
        window.open("https://programaaoseulado.com.br/", "_blank");
        showRedirectPopup.value = false;
      }, 4000);
    };

    let host = `//${document.location.hostname}`;
    const isLocalhost = /localhost/g.test(host);

    if (isLocalhost) {
      host += ":8000";
    }

    onMounted(() => {
      // Randomize the initial slide
      const slideQuantity = 5;
      const randomSlide = Math.floor(Math.random() * slideQuantity);
      const script = document.createElement("script");
      script.src =
        "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
      script.onload = () => {
        new Swiper(".swiper", {
          centeredSlides: true,
          loop: true,
          loopedSlides: 3,
          preloadImages: true,
          watchSlidesProgress: true,
          loopAdditionalSlides: 1,
          observer: true,
          observeParents: true,
          updateOnWindowResize: true,
          initialSlide: randomSlide,
          autoplay: false,
          breakpoints: {
            320: {
              slidesPerView: 1.5,
              spaceBetween: 0
            },
            480: {
              slidesPerView: 1.7,
              spaceBetween: 0
            },
            768: {
              slidesPerView: 2.4,
              spaceBetween: 0
            },
            1024: {
              slidesPerView: 2.4,
              spaceBetween: 30
            },
            1440: {
              slidesPerView: 2.8,
              spaceBetween: 30
            }
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          effect: "coverflow",
          coverflowEffect: {
            rotate: 0,
            stretch: 50,
            depth: 120,
            modifier: 1.78,
            slideShadows: false
          },
          on: {
            init: function() {
              setTimeout(() => {
                this.update();
              }, 400);
            },
            click: function(swiper, event) {
              const slide = event.target.closest(".swiper-slide");
              if (!slide) return;

              if (slide.classList.contains("raras")) {
                handleRarasRedirect();
                return;
              }

              const pathMap = {
                ossos: "/ossos",
                intestino: "/intestino",
                medula: "/medulaossea",
                autoimunidade: "/autoimunidade",
                pulmao: "/pulmao"
              };

              const slideClass = Array.from(slide.classList).find(className =>
                Object.prototype.hasOwnProperty.call(pathMap, className)
              );

              if (slideClass) {
                window.location.href = `${host}${pathMap[slideClass]}`;
              }
            }
          }
        });
      };
      document.head.appendChild(script);
    });

    return {
      host,
      showRedirectPopup,
      handleRarasRedirect
    };
  }
};
</script>

<style lang="scss">
$o-medicines-slider: ".o-medicines-slider";

$slider-instruction: ".slider-instruction";

#{$slider-instruction} {
  @media (min-width: 1440px) {
    margin-bottom: 10px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 55px;
  }
  @media (max-width: 1366px) {
    margin-bottom: 55px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 55px;
  }
  @media (max-width: 1024px) {
    margin-bottom: -50px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
  @media (max-width: 480px) {
    margin-bottom: -50px;
  }
  @media (max-width: 320px) {
    margin-bottom: -30px;
  }

  width: 100%;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }
}

#{$o-medicines-slider} {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  user-select: none;

  @media (hover: hover) {
    a:hover {
      background-color: transparent !important;
    }
  }

  .swiper-container {
    position: relative;
    width: 80%;
    @media (max-width: 1440px) {
      width: 80%;
    }
    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
    @media (max-width: 320px) {
      width: 100%;
    }
  }

  .swiper {
    padding: 20px 0;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    .swiper-wrapper {
      z-index: 1;
      width: 0% !important;
    }

    @media (max-width: 1440px) {
      width: 80%;
    }
    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
    @media (max-width: 320px) {
      width: 100%;
    }
  }

  img {
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
  }

  a {
    user-select: none;
  }

  h2 {
    user-select: none;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    width: 50px;
    height: 50px;
    background: none;
    top: 50%;
    transform: translateY(-25%);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    color: #40c3e7;

    &::after {
      font-family: swiper-icons;
      font-size: 35px;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }

    &:hover {
      transform: translateY(-25%) scale(1.3);
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-slide {
    transition: all 0.3s ease;
    opacity: 0.99;
    transform: scale(0.8);
    z-index: 1;
    cursor: pointer;
    position: relative;

    &:hover {
      transform: scale(0.85);
    }

    &-prev,
    &-next {
      opacity: 0.99;
      transform: scale(0.85);
      z-index: 2;

      &:hover {
        transform: scale(0.9);
      }
    }

    &-active {
      opacity: 1;
      transform: scale(1);
      z-index: 3;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__item {
    border-radius: 15px;
    // overflow: hidden;
    height: 350px;
    width: 100%;
    max-width: 500px;
    transition: all 0.3s ease;
    transform-origin: center center;
    margin: 0 auto;

    @media (max-width: 1440px) {
      max-width: 450px;
      height: 320px;
      border-radius: 15px;
    }

    @media (max-width: 1024px) {
      max-width: 400px;
      height: 300px;
      border-radius: 15px;
    }

    @media (max-width: 768px) {
      max-width: 350px;
      height: 280px;
      border-radius: 15px;
    }

    @media (max-width: 480px) {
      max-width: 280px;
      height: 250px;
      border-radius: 15px;
    }

    @media (max-width: 320px) {
      max-width: 250px;
      height: 220px;
      border-radius: 15px;
    }

    &__wrapper {
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      user-select: none;

      a {
        color: #fff;
        text-decoration: none;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .image-wrapper {
        height: 90%;

        img {
          padding: 0.75%;
          width: calc(100% - 0.75% * 2);
          height: calc(100% - 0.75%);
          object-fit: cover;
          border-radius: 15px 15px 0 0;
        }
      }

      .content-wrapper {
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 30px;
      }

      .icon-wrapper {
        width: 15%;
        height: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      h2 {
        height: 100%;
        width: 85%;
        color: #fff;
        font-size: 1.2rem;
        flex-grow: 1;
        display: flex;
        justify-content: end;
        align-items: center;
      }
    }
    @media (min-width: 1440px) {
      .icon-wrapper {
        height: 70%;
      }
    }

    @media (max-width: 1440px) {
      .content-wrapper {
        padding-inline: 30px;
      }
    }

    @media (max-width: 1024px) {
      .content-wrapper {
        padding-inline: 30px;
        h2 {
          font-size: 1rem;
        }
      }
    }

    @media (max-width: 768px) {
      .content-wrapper {
        padding-inline: 30px;
        h2 {
          font-size: 1rem;
        }
      }
    }

    @media (max-width: 480px) {
      .content-wrapper {
        padding-inline: 30px;
        h2 {
          font-size: 1rem;
        }
      }
    }

    &.ossos {
      background-color: #807bc3;
    }

    &.intestino {
      background-color: #a498f3;
    }

    &.medula {
      background-color: #78bbef;
    }

    &.autoimunidade {
      background-color: #54d6fc;
    }

    &.pulmao {
      background-color: #40c3e7;
    }

    &.raras {
      background-color: #2eb0d4;
    }
  }
}
</style>
